import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

import { ContactUs } from "shared/components/Auth/ContactUs";
import LoginForm from "shared/components/Auth/LoginForm";
import RegistrationForm from "shared/components/Auth/RegistrationForm";
import { RestorePass } from "shared/components/Auth/RestorePass";
import { UnconnectedErrorBoundary as ErrorBoundary } from "shared/components/ErrorBoundary";

import { initSentry } from "shared/helpers/utils";

import "i18n";

import "@brandAssets/scss/main.scss";

initSentry();

if (document.getElementById("login")) {
  ReactDOM.createRoot(document.getElementById("login")).render(
    <ErrorBoundary>
      <LoginForm />
    </ErrorBoundary>
  );
}

if (document.getElementById("registration")) {
  ReactDOM.createRoot(document.getElementById("registration")).render(
    <ErrorBoundary>
      <RegistrationForm />
    </ErrorBoundary>
  );
}

if (document.getElementById("restore")) {
  ReactDOM.createRoot(document.getElementById("restore")).render(
    <ErrorBoundary>
      <RestorePass />
    </ErrorBoundary>
  );
}

if (document.getElementById("contact_us")) {
  ReactDOM.createRoot(document.getElementById("contact_us")).render(
    <ErrorBoundary>
      <ContactUs />
    </ErrorBoundary>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
